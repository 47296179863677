import React, { Component } from 'react';
import cn from 'classnames';

import './full-screen-video.scss';

class FullScreenVideo extends Component {
	render() {
		const { video, isOpen, handleToggleVideo } = this.props;
		return (
			<div className={`video-player ${cn({ active: isOpen })} `}>
				<div className="video-wrapper">
					<div className="close-video-player" onClick={handleToggleVideo} />
					<div className="video">
						{(video && isOpen)
								&& (
									<video
										controls
										autoPlay
									>
										<source src={`${process.env.REACT_APP_API_ENDPOINT}/${video}`} type="video/mp4" />
										{'Your browser does not support HTML5 video.'}
									</video>
								)
						}
					</div>
				</div>
			</div>
		);
	}
}

export default FullScreenVideo;
