import axios from 'axios';

import { logError } from '../../infrastructure/error-handling.js';

class HomePageService {
	getHomePageData(language) {
		return axios
			.get(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/singletons/get/homepage?lang=${language}`,
			)
			.then(res => res.data)
			.catch(logError);
	}

	submitQuestion(data) {
		const formData = new FormData();
		formData.set('form[name]', data.name);
		formData.set('form[email]', data.email);
		formData.set('form[question]', data.question);

		return axios
			.post(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/forms/submit/questions?token=${process.env.REACT_APP_API_TOKEN}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			)
			.then(res => res)
			.catch(logError);
	}
}

export default new HomePageService();
