/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import Swiper from 'react-id-swiper';
import Media from 'react-media';

import './infinite-scroll.scss';

const Partner = ({ partner }) => (
	<div className="partner">
		<div className="image-wrapper">
			<div
				className="rounded-bg-color"
				style={{
					backgroundColor: partner.color,
					transition: 'ease-in-out 0.1s',
				}}
			/>
			{partner.whiteLogo && partner.whiteLogo.path && (
				<img
					className="white_logo"
					src={`${process.env.REACT_APP_API_ENDPOINT
						+ partner.whiteLogo.path}`}
					alt={partner.name}

				/>
			)}
			{partner.blackLogo && partner.blackLogo.path && (
				<img
					className="black_logo"
					src={`${process.env.REACT_APP_API_ENDPOINT + partner.blackLogo.path}`}
					alt={partner.name}
				/>
			)}

		</div>
	</div>
);

const PartnersRow = ({ partner }) => (
	<div className="row">
		{partner.map(partnerDetails => (
			<div key={partnerDetails.id} className="col-xs-6 col-md-4">
				<Partner partner={partnerDetails} />
			</div>
		))}
	</div>
);

const Partners = ({ partners }) => {
	const params = {
		direction: 'vertical',
		slidesPerView: 4,
		loop: true,
		speed: 5000,
		autoplay: {
			delay: 0,
		},
	};

	// rows for Swiper desktop
	const sliced = [];
	const rows = Math.ceil(partners.length / 3);
	let currentRow = 0;
	while (currentRow < rows) {
		sliced.push(partners.slice(currentRow * 3, currentRow * 3 + 3));
		currentRow += 1;
	}

	// this code doenst let empty spaces on infinity scroll. it copies an element
	// from the middle of the array and places at the missing space to complete
	// the entire row
	if (sliced[sliced.length - 1].length < 3) {
		sliced[sliced.length - 1].push(partners[Math.floor(partners.length / 2)]);

		if (sliced[sliced.length - 1].length < 3) {
			sliced[sliced.length - 1].push(partners[Math.floor(partners.length / 2) + 1]);
		}
	}

	// rows for Swiper mobile
	const slicedXS = [];
	const rowsXS = Math.ceil(partners.length / 2);
	let currentRowXS = 0;
	while (currentRowXS < rowsXS) {
		slicedXS.push(partners.slice(currentRowXS * 2, currentRowXS * 2 + 2));
		currentRowXS += 1;
	}

	// read comment lines from 76-77-78
	if (slicedXS[slicedXS.length - 1].length < 2) {
		slicedXS[slicedXS.length - 1].push(partners[Math.floor(partners.length / 2)]);
	}

	return (
		<div className="infinite-scroll-slider">
			<div className="slider-wrapper">
				<div className="grid">
					{partners && (
						<Media query={{ 'max-width': '62em' }}>
							{matches => (matches ? (
								<Swiper {...params}>
									{slicedXS.map(partner => (
										<div key={partner.id}>
											<PartnersRow partner={partner} />
										</div>
									))}
								</Swiper>
							) : (
								<Swiper {...params}>
									{sliced.map(partner => (
										<div key={partner.id}>
											<PartnersRow partner={partner} />
										</div>
									))}
								</Swiper>
							))
							}
						</Media>
					)}
				</div>
			</div>
		</div>
	);
};

export default Partners;
