import axios from 'axios';

import { logError } from '../../infrastructure/error-handling.js';

class StoriesService {
	getStoriesBySlug(language, slug) {
		return axios
			.post(
				`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/storiesList?
			lang=${language}&filter[slug]=${slug}`,
				{
					lang: language,
					populate: 1,
				},
			)
			.then(res => res)
			.catch(error => Promise.reject(error));
	}

	getData(language) {
		return axios
			.get(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/singletons/get/stories?lang=${language}`,
			)
			.then(res => res.data)
			.catch(logError);
	}

	getCategories({ language }) {
		return axios
			.post(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/collections/get/storiesCategories`,
				{
					lang: language,
				},
			)
			.then(res => res)
			.catch(error => Promise.reject(error));
	}

	getStoriesList({
		language, limit, category, skip = 0, highlighted, partner,
	}) {
		const categoryFilter = {
			'category._id': category,
		};

		const filterPartner = {
			'partner._id': partner,
		};

		const fullFilter = {
			'category._id': category,
			'partner._id': partner,
		};

		let filter = {
			highlighted: highlighted,
		};


		if (partner && category) {
			filter = fullFilter;
		} else if (partner) {
			filter = filterPartner;
		} else if (category) {
			filter = categoryFilter;
		} else if (!highlighted) {
			filter = null;
		}

		return axios
			.post(
				`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/storiesList`,
				{
					lang: language,
					populate: 1,
					skip,
					limit,
					filter,
					sort: {
						date: -1,
					},
				},
			)
			.then(res => res)
			.catch(error => Promise.reject(error));
	}

	submitNewsletter({ email }) {
		return axios
			.post(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/forms/submit/newsletter?token=${process.env.REACT_APP_API_TOKEN}`,
				{
					__mailfrom: process.env.REACT_APP_COCKPIT_MAILER_FROM,
					form: {
						email,
					},
				},
			)
			.then(res => res)
			.catch(logError);
	}

	getPartnersName({ language }) {
		return axios
			.post(
				`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/storiesList`,
				{
					lang: language,
					populate: 1,
					sort: {
						date: -1,
					},
				},
			)
			.then(res => res)
			.catch(logError);
	}
}

export default new StoriesService();
