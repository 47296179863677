import React, { Component, Fragment } from 'react';
import { Field, Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';

import HomePageService from '../../features/home/home.service.js';

import { FormTextArea, FormTextBox } from '../ui-toolkit/form-inputs.js';
import Utils from '../shared/util.js';

import success from '../../assets/icons/success2.svg';
import error from '../../assets/icons/error2.svg';

import './form.scss';


const FeedbackMessage = ({
	active, icon, handle, classFeedback, title, subtitle1, subtitle2, buttonLabel,
}) => (
	<div className={`submitted-form ${cn({ active })} `}>
		<div className="submitted-form-wrapper">
			<div className="submitted-form-content">
				<img src={icon} alt="success" />
				<p className="title">{title}</p>
				<p>{subtitle1}</p>
				<p>{subtitle2}</p>
				<p className={`send-again ${classFeedback} `} onClick={handle}>{buttonLabel}</p>
			</div>
		</div>
	</div>
);

class Form extends Component {
	constructor(props) {
		super(props);
		this.contactUsRef = React.createRef();
	}

	state = {
		submittedForm: false,
		hasFormError: false,
	};

	scrollToRef = () => {
		window.scrollTo({
			top: (this.contactUsRef.current.offsetTop - 200),
			behavior: 'smooth',
		});
	};

	newQuestion() {
		this.setState({ submittedForm: false });
	}

	tryAgain() {
		this.setState({ hasFormError: false });
	}

	render() {
		const { intl } = this.props;
		const { submittedForm, hasFormError } = this.state;

		return (
			<div className="formik-section" ref={this.contactUsRef}>
				<Formik
					enableReinitialize
					initialValues={{
						name: '',
						email: '',
						question: '',
					}}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						setSubmitting(true);
						this.setState({ hasFormError: false });
						HomePageService.submitQuestion(values)
							.then(() => {
								setSubmitting(false);
								this.setState({
									submittedForm: true,
									hasFormError: false,
								});
								this.scrollToRef();
								resetForm();
							})
							.catch(() => {
								setSubmitting(false);
								this.setState({
									hasFormError: true,
								});
								this.scrollToRef();
							});
					}}
				>
					{({
						errors,
						dirty,
						touched,
						handleSubmit,
						isSubmitting,
						isValid,
					}) => (
						<form onSubmit={handleSubmit} noValidate>
							<div className="row center-xs">
								<div className="col-xs-12 col-md-12 center-xs field">
									<Field
										component={FormTextBox}
										type="text"
										name="name"
										label={intl.formatMessage({
											id: 'homepage.form.name_label',
										})}
										placeholder={intl.formatMessage({
											id: 'homepage.form.name_placeholder',
										})}
										fullWidth
										error={errors.name && touched.name}
										validate={(value) => {
											let error = false;
											if (!value) {
												error = intl.formatMessage({
													id: 'errors.required',
												});
											}
											return error;
										}}
										validationMessage={errors.name}
										required
									/>
								</div>
								<div className="col-xs-12 col-md-12 center-xs field">
									<Field
										component={FormTextBox}
										type="email"
										name="email"
										label={intl.formatMessage({
											id: 'homepage.form.email_label',
										})}
										placeholder={intl.formatMessage({
											id: 'homepage.form.email_placeholder',
										})}
										fullWidth
										error={errors.email && touched.email}
										validate={(value) => {
											let error = false;
											if (!Utils.validateEmail(value)) {
												error = intl.formatMessage({
													id: 'errors.invalid_email',
												});
											}
											if (!value) {
												error = intl.formatMessage({
													id: 'errors.required',
												});
											}
											return error;
										}}
										validationMessage={errors.email}
										required
									/>
								</div>
								<div className="col-xs-12 col-md-12 center-xs field">
									<Field
										component={FormTextArea}
										name="question"
										placeholder={intl.formatMessage({
											id: 'homepage.form.question_placeholder',
										})}
										label={intl.formatMessage({
											id: 'homepage.form.question_label',
										})}
										rows={7}
										fullWidth
										error={errors.question && touched.question}
										validate={(value) => {
											let error = false;
											if (value.length < 10) {
												error = intl.formatMessage({
													id: 'errors.too_short',
												});
											}
											if (!value) {
												error = intl.formatMessage({
													id: 'errors.required',
												});
											}

											return error;
										}}
										validationMessage={errors.question}
										required
									/>
								</div>
								<div className="col-xs-12 col-md-12 field">
									<button
										type="submit"
									>
										<FormattedMessage id="homepage.form.submit">
											{txt => <Fragment>{txt}</Fragment>}
										</FormattedMessage>
									</button>
								</div>
							</div>
						</form>
					)}
				</Formik>
				<FeedbackMessage
					active={submittedForm}
					classFeedback="success"
					icon={success}
					handle={() => this.newQuestion()}
					title={intl.formatMessage({ id: 'homepage.form.submit.success.title' })}
					subtitle1={intl.formatMessage({ id: 'homepage.form.submit.success.subtitle1' })}
					subtitle2={intl.formatMessage({ id: 'homepage.form.submit.success.subtitle2' })}
					buttonLabel={intl.formatMessage({ id: 'homepage.form.submit.success.send-again' })}
				/>
				<FeedbackMessage
					active={hasFormError}
					classFeedback="error"
					icon={error}
					handle={() => this.tryAgain()}
					title={intl.formatMessage({ id: 'homepage.form.submit.error.title' })}
					subtitle1={intl.formatMessage({ id: 'homepage.form.submit.error.subtitle1' })}
					subtitle2={intl.formatMessage({ id: 'homepage.form.submit.error.subtitle2' })}
					buttonLabel={intl.formatMessage({ id: 'homepage.form.submit.error.send-again' })}
				/>
			</div>
		);
	}
}

export default injectIntl(Form);

export { Form };
