import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import Swipe from 'react-easy-swipe';

import defaultChallengeImage from '../../assets/images/defaultChallengeImage.png';
import icon_open from '../../assets/icons/ico_open.svg';

import './slider.scss';

// inline style due "tree dots" not showing up on scss stylesheet
const descriptionStyle = {
	color: '#252525',
	display: '-webkit-box',
	width: '100%',
	height: '90px',
	margin: '0 auto',
	marginBottom: '20px',
	fontSize: '16px',
	lineHeight: '28px',
	WebkitLineClamp: '3',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

const titleStyle = {
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
	marginBottom: '20px',
	textOverflow: 'ellipsis',
};
const Card = ({ slide, handlePreviousSlide, handleNextSlide }) => (
	<div className="card-wrapper">
		<div className="card">
			<div className="content">
				<h5>{slide.category.id}</h5>
				<h4 style={titleStyle}>{slide.title}</h4>
				<div style={descriptionStyle}>{slide.summary}</div>
				<div className="read-more">
					{slide.storyLink && slide.storyLink ? (
						<a
							className="external-link"
							href={`//${slide.storyLink}`}
							target="_blank"
							rel="noreferrer noopener"
						>
							<FormattedMessage id="homepage.readMore" />

							<img src={icon_open} alt="open" />
						</a>
					) : (
						<Link className="read-link" to={`/stories/${slide.slug}`}>
							<FormattedMessage id="homepage.readMore" />
						</Link>
					)}
				</div>
				<div className="arrows">
					<div
						className="arrow left nextButton"
						onClick={handlePreviousSlide}
					/>
					<div className="arrow right" onClick={handleNextSlide} />
				</div>
			</div>
		</div>
	</div>
);

class Slider extends React.Component {
	static defaultProps = {
		unity: 60,
		margin: 20,
		slides: [],
		hasPagination: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			position: 0,
			canAnimate: true,
			swipeable: props.slides.data.length > 0,
			isAnimating: false,
			slideIndex: 0,
		};
	}

	previousNextSlide = (slideIndex) => {
		const { slides } = this.props;
		if (slideIndex < 0) {
			this.setState({ slideIndex: slides.data.length - 1 });
		} else if (slideIndex > slides.data.length - 1) {
			this.setState({ slideIndex: 0 });
		} else {
			this.setState({ slideIndex });
		}
	};

	onSwipeLeft = () => {
		const { slides } = this.props;
		const { isAnimating, slideIndex, swipeable } = this.state;

		if (!swipeable) return;
		const max = slides.data.length - 1;

		if (isAnimating) {
			return;
		}

		if (slideIndex === max) {
			this.previousNextSlide(0);
		}

		this.previousNextSlide(slideIndex + 1);
	};

	onSwipeRight = () => {
		const { slides } = this.props;
		const { isAnimating, slideIndex, swipeable } = this.state;

		if (!swipeable) return;
		const max = slides.data[0].length - 1;

		if (isAnimating) {
			return;
		}

		if (slideIndex === max) {
			this.previousNextSlide(0);
		}

		this.previousNextSlide(slideIndex - 1);
	};

	render() {
		const { slides } = this.props;
		const { slideIndex } = this.state;

		return (
			<Swipe
				className="slider"
				onSwipeLeft={this.onSwipeLeft}
				onSwipeRight={this.onSwipeRight}
			>
				{slides.data
					&& slides.data.map((slide, index) => (
						<div
							className={`slide-wrapper ${cn({
								active: slideIndex === index,
							})} `}
							key={slide.title}
						>
							<div className="slide">
								<div
									className="image"
									style={{
										backgroundImage:
											slide.Image && slide.Image.path
												? `url('${process.env.REACT_APP_API_ENDPOINT
														+ slide.Image.path}')`
												: defaultChallengeImage,
										backgroundSize: 'cover',
										backgroundPosition: 'center',
									}}
								/>
								<div className="container container-fluid ">
									<div className="row">
										<div className="col-xs-12 col-md-6">
											<Card
												slide={slide}
												handlePreviousSlide={() => this.previousNextSlide(slideIndex - 1)
												}
												handleNextSlide={() => this.previousNextSlide(slideIndex + 1)
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				<div className="controls">
					<div className="container container-fluid">
						<div className="controls-wrapper">
							<div className="bullets">
								{slides.data
									&& slides.data.map((slide, index) => (
										<div
											key={slide.image && slide.image.path}
											className={`bullet ${cn({
												active: slideIndex === index,
											})} `}
											onClick={() => this.previousNextSlide(index)}
										/>
									))}
							</div>
						</div>
					</div>
				</div>
			</Swipe>
		);
	}
}

export default Slider;
