import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import OnVisible, { setDefaultProps } from 'react-on-visible';
import Media from 'react-media';

import { actions } from '../../shared/store.js';

import StoriesSlider from '../../components/news-slider/slider.component.js';
import InfinitScrool from '../../components/infinite-scrool/infinite-scroll.component.js';
import QuestionForm from '../../components/question-form/form.component.js';
import VideoFullScreen from '../../components/full-screen-video/full-screen-video.component.js';

// import PageNotFound from '../../components/not-found/page-not-found.js';

import HomePageService from './home.service.js';
import StoriesService from '../stories/stories.service.js';
import PartnersService from '../portfolio/portfolio.service.js';

import './home.page.scss';

class HomePage extends Component {
	static prefetchData = async language => Promise.all([
		HomePageService.getHomePageData(language),
		StoriesService.getStoriesList({ language, highlighted: true, limit: 4 }),
		PartnersService.getPartnersName(language),
	]);

	state = {
		hasError: false,
		isOpen: false,
	};

	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		this.contactUsRef = React.createRef();
	}

	componentDidMount() {
		const { homepageData, setLoading, homeStoriesList } = this.props;

		if (!homepageData || !homeStoriesList) {
			this.fetchData();
		} else {
			setLoading(false);
		}
	}

	componentDidUpdate(prevProps) {
		const { language } = this.props;

		if (prevProps.language.value !== language.value) {
			this.fetchData();
		}
	}

	componentWillUnmount() {
		clearTimeout(this.sliderTimer);
	}

	fetchData = () => {
		const {
			setLoading,
			setHomepageData,
			setHomeStoriesList,
			setHomepagePartners,
			language,
		} = this.props;

		setLoading(true);

		HomePage.prefetchData(language.value)
			.then(([pageData, homeStoriesList, partners]) => {
				setHomepageData(pageData);
				setHomeStoriesList({
					data: homeStoriesList.data.entries,
					total: homeStoriesList.data.total,
				});
				setHomepagePartners(partners);
				setLoading(false);
			})
			.catch(() => {
				const { history } = this.props;
				setLoading(false);
				history.push('/404');
				this.setState({
					hasError: true,
				});
			});
	};

	toggleVideo = () => {
		this.setState(prevState => ({ isOpen: !prevState.isOpen }));
	};

	scrollToAboutUS = () => {
		window.scrollTo({
			top: this.myRef.current.offsetTop,
			behavior: 'smooth',
		});
	};

	render() {
		const { isOpen, hasError } = this.state;
		const {
			intl,
			homepageData,
			homeStoriesList,
			homepagePartners,
		} = this.props;

		setDefaultProps({
			visibleClassName: 'visible',
			percent: 20,
		});

		if (hasError || !homepageData || !homeStoriesList || !homepagePartners) {
			return null;
		}

		const {
			title,
			subtitle,
			aboutUsTitle,
			partnersTitle,
			partnersSubtitle,
			contactUsTitle,
			video,
			videoBackground,
		} = homepageData;

		return (
			<div id="home-page">
				<Helmet>
					<title>
						{intl.formatMessage({
							id: 'navigation.homepage',
						})}
					</title>
					<meta
						name="description"
						content={title}
					/>
					<link
						rel="canonical"
						href={`${
							process.env.REACT_APP_URL
						}`}
					/>

					<meta
						property="og:url"
						content={`${
							process.env.REACT_APP_URL
						}`}
					/>
				</Helmet>

				<section className="initial-section">
					{videoBackground.path && (
						<img
							className="initial-section-image"
							src={`${process.env.REACT_APP_API_ENDPOINT + videoBackground.path}`}
							alt="Partner-With-us-Header"
						/>
					)}
					<div className="bg-video">
						<Media query="(min-width: 992px)">
							{video && (
								<video autoPlay loop muted playsInline>
									<source
										src={`${process.env.REACT_APP_API_ENDPOINT}/${video}`}
										type="video/mp4"
									/>
									{'Your browser does not support HTML5 video.'}
								</video>
							)}
						</Media>
						<div className="da	rk-layer" />
						<div className="container container-fluid">
							<OnVisible className="content-wrapper">
								<div className="content">
									<h1>
										{title}
										<span className="green">.</span>
									</h1>
									<span className="play-video" onClick={this.toggleVideo}>
										<FormattedMessage id="homepage.play_video">
											{txt => <Fragment>{txt}</Fragment>}
										</FormattedMessage>
									</span>
									<span className="scroll-down" onClick={this.scrollToAboutUS}>
										<FormattedMessage id="homepage.scroll_down">
											{txt => <Fragment>{txt}</Fragment>}
										</FormattedMessage>
									</span>
								</div>
							</OnVisible>
						</div>
					</div>
				</section>

				<section className="our-roots" ref={this.myRef}>
					<div className="container container-fluid">
						<OnVisible className="content">
							<div className="row">
								<div className="col-xs-12 col-sm-3 left-title">
									<p>
										{intl.formatMessage({
											id: 'homepage.about_us',
										})}
									</p>
								</div>
								<div className="col-xs-12 col-sm-9 right-text-wrapper">
									<div className="col-xs-12">
										<h6>{aboutUsTitle}</h6>
									</div>
									<div className="col-xs-12 our-roots-subtitle">
										<h6>{subtitle}</h6>
									</div>
									<div className="col-xs-12 more-about-wrapper">
										<Link to="/about-us">
											<span className="more-about">
												{intl.formatMessage({
													id: 'homepage.more_about',
												})}
											</span>
										</Link>
									</div>
								</div>
							</div>
						</OnVisible>
					</div>
				</section>

				<section className="partners">
					<div className="container container-fluid">
						<div className="row">
							<div className="col-xs-12 col-md-5">
								<OnVisible className="content">
									<h2>{partnersTitle}</h2>
									<h4>{partnersSubtitle}</h4>
									<Link to="/portfolio">
										<span className="link_to">
											{intl.formatMessage({
												id: 'homepage.see_our_full_portfolio',
											})}
										</span>
									</Link>
								</OnVisible>
							</div>
							<div className="col-xs-12 col-md-7 partners-wrapper">
								<div className="partners-list">
									<InfinitScrool partners={homepagePartners.data} />
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="home-slider">
					<StoriesSlider slides={homeStoriesList} />
				</section>

				<section className="contact-us">
					<OnVisible className="content">
						<div className="container container-fluid">
							<h6>
								{intl.formatMessage({
									id: 'homepage.contact_us',
								})}
							</h6>
							<div className="row">
								<div className="col-xs-12 col-md-6 col-lg-6">
									<h3>{contactUsTitle}</h3>
								</div>
								<div className="col-xs-12 col-md-5 col-lg-6">
									<QuestionForm />
								</div>
							</div>
						</div>
					</OnVisible>
				</section>

				<VideoFullScreen
					video={video}
					isOpen={isOpen}
					handleToggleVideo={this.toggleVideo}
				/>
			</div>
		);
	}
}

const mapToProps = ({
	language,
	homepageData,
	homeStoriesList,
	homepagePartners,
	isLoading,
}) => ({
	language,
	homepageData,
	homeStoriesList,
	homepagePartners,
	isLoading,
});

export default withRouter(
	injectIntl(
		connect(
			mapToProps,
			actions,
		)(HomePage),
	),
);

export { HomePage };
