class PortfolioModel {
	id;

	slug;

	title;

	description;

	slogan;

	founded;

	invested;

	name;

	blackLogo;

	whiteLogo;

	colorLogo;

	link;

	websiteLink;

	facebookLink;

	linkedInLink;

	instagramLink;

	color;

	theme;

	sdgsTitle;

	sdgs;

	infographicTitle;

	infographicDescriptionTitle;

	infographicDescriptionLabel;

	infographicLink;

	gallery;

	quote;

	constructor({
		_id = '',
		name = '',
		slug = '',
		title = '',
		description = '',
		founded = '',
		invested = '',
		slogan = '',
		blackLogo = '',
		whiteLogo = '',
		colorLogo = '',
		link = '',
		websiteLink = '',
		facebookLink = '',
		linkedInLink = '',
		instagramLink = '',
		color = '',
		theme = '',
		image = null,
		video = '',
		sdgsTitle = '',
		sdgs = [],
		infographicTitle = '',
		infographicDescriptionTitle = '',
		infographicDescriptionLabel = '',
		infographicLink = '',
		gallery = [],
		quote = '',
	}) {
		this.id = _id;
		this.slug = slug;
		this.title = title;
		this.description = description;
		this.slogan = slogan;
		this.founded = founded;
		this.invested = invested;
		this.name = name;
		this.whiteLogo = whiteLogo;
		this.blackLogo = blackLogo;
		this.colorLogo = colorLogo;
		this.link = link;
		this.websiteLink = websiteLink;
		this.facebookLink = facebookLink;
		this.linkedInLink = linkedInLink;
		this.instagramLink = instagramLink;
		this.color = color;
		this.theme = theme;
		this.image = image;
		this.video = video;
		this.sdgsTitle = sdgsTitle;
		this.sdgs = sdgs;
		this.infographicTitle = infographicTitle;
		this.infographicDescriptionTitle = infographicDescriptionTitle;
		this.infographicDescriptionLabel = infographicDescriptionLabel;
		this.infographicLink = infographicLink;
		this.gallery = gallery;
		this.quote = quote;
	}
}

export default PortfolioModel;
