import axios from 'axios';

import { logError } from '../../infrastructure/error-handling.js';
import PortfolioModel from './portfolio.model.js';

class PortfolioService {
	getPortfolioPageData(language) {
		return axios
			.get(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/singletons/get/portfolio?lang=${language}`,
			)
			.then(res => res.data)
			.catch(logError);
	}

	getPartnersName({ language, limit, skip }) {
		return axios
			.post(
				`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/partners`,
				{
					lang: language,
					sort: {
						date: -1,
					},
					limit,
					skip,
				},
			)
			.then(({ data }) => ({
				data: data.entries.map(entry => new PortfolioModel(entry)),
				total: data.total,
			}))
			.catch(logError);
	}

	getCategories({ language }) {
		return axios
			.post(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/collections/get/partnersThemes`,
				{
					lang: language,
					sort: {
						theme: 1,
					},
				},
			)
			.then(res => res)
			.catch(logError);
	}
}

export default new PortfolioService();
